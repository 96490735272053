import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

class PrivacyPage extends React.Component {
    render() {
      const siteTitle = 'Privacy';
        
      return (
        <Layout title={siteTitle}>
        <Seo title="Privacy page" />
        <article>
            <h1>Privacy information</h1>
            <p>
            codingfreaks uses Google Analytics to gather information on site visits. We do this in order to improve our web sites experience. 
            All data we collect is anonymized and you have the option to disable the collection via the settings-icon on the bottom left.
            </p>
            <h1>For German visitors</h1>
            <p>
                Because we are located in Germany we have to ensure that our German visitors are provided with the legally enforced informations. That
                is why the following section is integrated in German language.
            </p>
            <h2>Anbieterkennzeichnung</h2>
            <p>
                Inhaltlich verantwortlich für alle Inhalte auf dieser Webseite ist:
            </p>
            <p>
                Alexander Schmidt<br/>
                Herderstrasse 31<br/>
                D-39108 Magdeburg<br/>
            </p>
            <p>Bei Kontaktaufnahmen ist die E-Mail-Adresse info_at_codingfreaks.de zu verwenden.</p>
            <h2>Datenschutz</h2>
            <p>
                Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. („Google“). Die Benutzung erfolgt auf der Grundlage des Art. 6 
                Abs. 1 S. 1 lit. f. DSGVO. Google Analytics verwendet sog. „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden und die eine 
                Analyse der Benutzung der Webseite durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung der Webseite wie Browser-Typ/-Version, verwendetes 
                Betriebssystem, Referrer-URL (die zuvor besuchte Seite), Hostname des zugreifenden Rechners (IP-Adresse), Uhrzeit der Serveranfrage, werden in der Regel an einen Server von Google in 
                den USA übertragen und dort gespeichert. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. 
                Wir haben zudem auf dieser Webseite Google Analytics um den Code „anonymizeIP“ erweitert. Dies garantiert die Maskierung Ihrer IP-Adresse, sodass alle Daten anonym erhoben werden. 
                Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt.
            </p>
            <p>
                Im Auftrag des Betreibers dieser Website wird Google diese Information benutzen, um Ihre Nutzung der Webseite auszuwerten, um Reports über die Webseiten-Aktivitäten 
                zusammenzustellen und um weitere mit der Webseiten-Nutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Webseitenbetreiber zu erbringen. Sie können 
                die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall möglicherweise 
                nicht sämtliche Funktionen dieser Webseite vollumfänglich werden nutzen können.
            </p>
            <p>
                Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Webseite bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die 
                Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren: http://tools.google.com/dlpage/gaoptout?hl=de. 
                Alternativ zum Browser-Add-On, insbesondere bei Browsern auf mobilen Endgeräten, können Sie die Erfassung durch Google Analytics zudem verhindern, indem Sie auf diesen Link klicken. 
                Es wird ein Opt-Out-Cookie gesetzt, das die zukünftige Erfassung Ihrer Daten beim Besuch dieser Website verhindert. Der Opt-Out-Cookie gilt nur in diesem Browser und nur für unsere Website 
                und wird auf Ihrem Gerät abgelegt. Löschen Sie die Cookies in diesem Browser, müssen Sie das Opt-Out-Cookie erneut setzen. [Anm. Hinweise zur Einbindung des Opt-Out-Cookie finden Sie 
                unter: <a href="https://developers.google.com/analytics/devguides/collection/gajs/?hl=de#disable]" target="_blank" rel="noopener noreferrer">https://developers.google.com/analytics/devguides/collection/gajs/?hl=de#disable]</a>.
            </p>
            <p>
                Wir nutzen Google Analytics weiterhin dazu, Daten aus Double-Click-Cookies und auch AdWords zu statistischen Zwecken auszuwerten. Sollten Sie dies nicht wünschen, können Sie dies über den 
                Anzeigenvorgaben-Manager (<a href="http://www.google.com/settings/ads/onweb/?hl=de" target="blank">http://www.google.com/settings/ads/onweb/?hl=de</a>) deaktivieren.
            </p>
            <p>
                Weitere Informationen zum Datenschutz im Zusammenhang mit Google Analytics finden Sie etwa in der Google Analytics-Hilfe (
                <a href="https://support.google.com/analytics/answer/6004245?hl=de" target="_blank" rel="noopener noreferrer">https://support.google.com/analytics/answer/6004245?hl=de</a>).
            </p>
        </article>
      </Layout>
      )
    }
  }
  
  export default PrivacyPage